/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import fetch from 'isomorphic-unfetch';

import { colors, font } from '../consts/style';
import * as endpoints from '../consts/endpoints';
import mq from '../style/mq';
import { Container, InnerSmallest } from './Elements';
import Down from '../images/down.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.orange};
  padding: 8rem 0;
  ${InnerSmallest} {
    h3 {
      font-family: 'Oceania';
      font-weight: normal;
      color: white;
      text-transform: uppercase;
      font-size: 3rem;
      margin-bottom: 4rem;
    }
    form {
      display: flex;
      flex-wrap: wrap;
      transition: all 0.4s ease;

      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }

      label {
        ${font.p};
      }

      .bold {
        * {
          font-weight: bold;
        }
      }

      input,
      textarea,
      .select-wrap {
        width: 100%;
        padding: 0.4rem 0.8rem;
        color: white;
        background: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 0.2rem solid white;
        font-size: 1.8rem;

        ::-webkit-input-placeholder {
          color: white;
          font-weight: bold;
        }

        select {
          width: 100%;
          background: ${colors.orange};
          border: none;
          border-radius: 0;
          color: white;
          -webkit-appearance: none;
        }
      }

      textarea {
        background: white;
        color: ${colors.darkBlue};
        ::-webkit-input-placeholder {
          color: ${colors.darkBlue};
        }
      }

      .select-wrap {
        position: relative;
        cursor: pointer;
        select {
          cursor: pointer;
          padding-right: 3rem;
        }
        img {
          position: absolute;
          width: 1.2rem;
          right: 1.2rem;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }

      textarea {
        min-height: 18rem;
      }

      .input-wrap {
        width: 100%;
        margin-bottom: 4rem;
      }

      .input-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .input-wrap {
          width: 49%;
          margin-right: 2%;
          &:nth-child(2n) {
            margin-right: 0;
          }
          ${mq.tabletSmall`
            width: 100%;
            margin-right: 0;
          `}
        }
      }
    }
    .button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .list {
        display: flex;
        align-items: center;
        color: white;
        margin-bottom: 1.2rem;
        button {
          height: 1.8rem;
          width: 1.8rem;
          padding: 0;
          border-radius: 0;
          background: transparent;
          border: 0.2rem solid white;

          &.selected {
            background: ${colors.darkBlue};
          }
        }
        p {
          margin: 0;
          margin-left: 1.6rem;
        }
      }
      .submit {
        ${font.buttonForm};
        margin-left: 4rem;
      }
    }
  }
`;

export default function ContactForm({ contactFormSubjects }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [list, setList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();
    if (loading) return;

    const body = JSON.stringify({
      'First Name': firstName,
      'Last Name': lastName,
      Company: company,
      Phone: phone,
      Email: email,
      Subject: subject,
      Message: message,
      Newsletter: list ? 'Yes' : 'No',
    });

    setLoading(true);

    fetch(endpoints.CONTACT, {
      method: 'post',
      body,
    })
      .then(res => res.json())
      .then(() => {
        setFirstName('');
        setLastName('');
        setCompany('');
        setPhone('');
        setEmail('');
        setSubject('');
        setMessage('');
        setList(false);
        setSuccess(true);
        setLoading(false);

        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      });
  }

  function getFormClass() {
    let c = '';
    if (loading) c += 'loading ';
    return c;
  }

  function getOpts() {
    const opts = contactFormSubjects.map(cfs => (
      <option key={cfs.id} value={cfs.title}>
        {cfs.title}
      </option>
    ));
    return opts;
  }
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <InnerSmallest>
            <h3>
              {success
                ? 'Thanks!  We will be in touch shortly.'
                : 'Get in touch'}
            </h3>
            <form className={getFormClass()} onSubmit={handleSubmit}>
              <div className="input-group">
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={e => setFirstName(e.target.value)}
                    value={firstName}
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={e => setLastName(e.target.value)}
                    value={lastName}
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={e => setCompany(e.target.value)}
                    value={company}
                    placeholder="Company"
                    required
                  />
                </div>
                <div className="input-wrap">
                  <input
                    type="tel"
                    onChange={e => setPhone(e.target.value)}
                    value={phone}
                    placeholder="Phone"
                    required
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-wrap">
                  <input
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="input-wrap">
                  <div className={`select-wrap ${!subject ? 'bold' : ''}`}>
                    <img src={Down} alt="Down arrow" />
                    <select
                      name="contact-select"
                      value={subject}
                      onChange={e => setSubject(e.target.value)}
                      defaultValue=""
                      required
                    >
                      <option className="default" value="" disabled>
                        Subject (Please Select)
                      </option>
                      {getOpts()}
                    </select>
                  </div>
                </div>
              </div>
              <div className="input-wrap">
                <textarea
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                  placeholder="Message"
                />
              </div>
              <div className="button">
                <div className="list">
                  <button
                    type="button"
                    className={`select ${list ? 'selected' : ''}`}
                    onClick={() => setList(!list)}
                  />
                  <p>I want to join your mailing list</p>
                </div>
                <button className="submit" type="submit">
                  <span>{loading ? 'Sending...' : 'Submit'}</span>
                </button>
              </div>
            </form>
          </InnerSmallest>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
