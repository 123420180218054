/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import _map from 'lodash/map';
import scrollToElement from 'scroll-to-element';

import { Container, Inner } from './Elements';
import Point from '../images/plus-icon-orange.svg';
import Down from '../images/down-orange.svg';
import { colors, font } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  margin: 6rem 0;
  h2 {
    font-family: 'Oceania', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 3rem;
    margin-bottom: 4rem;
  }
  .image-wrap {
    position: relative;
    margin-bottom: 6rem;

    ${mq.tabletSmall`
      display: none;
    `}
  }
`;

const MapImage = styled.img``;

const PointImage = styled.img.attrs({ src: Point })`
  position: absolute;
  cursor: pointer;
  z-index: 10;
  width: 5rem;

  &.north-america {
    top: 24%;
    left: 24%;
  }
  &.south-america {
    top: 70%;
    left: 32%;
  }
  &.africa {
    top: 60%;
    left: 60%;
  }
  &.australia {
    bottom: 12%;
    right: 8%;
  }
  &.asia {
    bottom: 60%;
    right: 10%;
  }
  &.europe {
    top: 3%;
    right: 40%;
  }
`;

const LocationModule = styled.div`
  ${mq.tabletSmall`
      display: none;
    `}
  .title {
    text-transform: uppercase;
    margin-bottom: 4rem;
  }
  .product {
    text-transform: uppercase;
    margin-bottom: 4rem;
    color: ${colors.orange};
  }
  .location-wrap {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &.has-cents {
      border-bottom: 0.1rem solid ${colors.dark};
      padding-bottom: 4.2rem;
      margin-bottom: 5rem;
    }

    .location {
      width: 25%;
      min-width: 30rem;
      padding-right: 2.4rem;
      margin-bottom: 2.4rem;
      display: flex;
      flex-direction: column;
      p,
      a {
        margin-top: 0;
        margin-bottom: 0.6rem;
        font-size: 1.6rem;
        span {
          font-weight: bold;
        }

        &.name {
          color: ${colors.orange};
          font-weight: bold;
          margin-bottom: 1.6rem;
        }
        &.orange {
          color: ${colors.orange};
        }
      }
    }
  }
`;

const LocationDrop = styled.div`
  display: none;
  ${mq.tabletSmall`
      display: flex;
      flex-direction: column;
    `}

  .oops {
    margin-bottom: 2.4rem;
    text-align: center;
  }
  .title {
    width: 100%;
    margin-bottom: 2.4rem;
    border-bottom: 0.3rem solid ${colors.orange};
    button {
      ${font.h3};
      font-weight: bold;
      color: ${colors.dark};
      text-transform: uppercase;
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: transparent;
      border: none;
      align-items: center;
      padding-bottom: 1.2rem;

      img {
        width: 2rem;
        transition: 0.3s ease all;
        ${({ open }) =>
          open ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'}
      }
    }
  }
  .product {
    text-transform: uppercase;
    margin-bottom: 2rem;
    color: ${colors.orange};
  }
  .location-wrap {
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 2.4rem;

    .location {
      width: 100%;
      padding-right: 1.2rem;
      margin-bottom: 2.4rem;
      display: flex;
      flex-direction: column;
      p,
      a {
        margin-top: 0;
        margin-bottom: 0.6rem;
        font-size: 1.6rem;
        span {
          font-weight: bold;
        }

        &.name {
          color: ${colors.orange};
          font-weight: bold;
          margin-bottom: 1.6rem;
        }
        &.orange {
          color: ${colors.orange};
        }
      }
    }
  }
`;

export default function ContactDiagram({ locations }) {
  const [selected, setSelected] = useState(locations[0].id);

  function renderImages() {
    return _map(locations, l => {
      return (
        <MapImage
          hidden={selected !== l.id}
          key={`${l.id}-img`}
          src={l.image.url}
          alt={l.title}
        />
      );
    });
  }

  function renderPoints() {
    return _map(locations, l => {
      return (
        <PointImage
          className={l.name.replace(/\s+/g, '-').toLowerCase()}
          onClick={() => setSelected(l.id)}
          key={`${l.id}-point`}
          alt={l.title}
          title={l.title}
        />
      );
    });
  }

  function renderLocationModules() {
    return _map(locations, l => {
      const hasPlugs = l.plugLocations.length > 0;
      const hasCents = l.centrifugeLocations.length > 0;
      return (
        <LocationModule hidden={selected !== l.id} key={`${l.id}-module`}>
          <div className="title">
            <h3>{l.name}</h3>
          </div>
          {!hasPlugs && !hasCents && (
            <p className="oops">Oops! No listed suppliers in {l.name} yet.</p>
          )}
          {hasPlugs && (
            <Fragment>
              <div className="product">
                <h3>Piercepoint TTB Plug</h3>
              </div>
              <div className={`location-wrap${hasCents ? ' has-cents' : ''}`}>
                {_map(l.plugLocations, pl => {
                  return (
                    <div key={pl.id} className="location">
                      {pl.name && <p className="name">{pl.name}</p>}
                      {pl.addressLine1 && <p>{pl.addressLine1}</p>}
                      {pl.addressLine2 && <p>{pl.addressLine2}</p>}
                      {pl.postalCode && <p>{pl.postalCode}</p>}
                      {pl.country && <p>{pl.country}</p>}
                      {pl.phone && (
                        <p>
                          <span>Phone:</span> {pl.phone}
                        </p>
                      )}
                      {pl.fax && (
                        <p>
                          <span>Fax:</span> {pl.fax}
                        </p>
                      )}
                      {pl.website && (
                        <a target="blank" href={pl.website} className="orange">
                          {pl.website}
                        </a>
                      )}
                      {pl.email && (
                        <a href={`mailto:${pl.email}`} className="orange">
                          {pl.email}
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )}
          {hasCents && (
            <Fragment>
              <div className="product">
                <h3>ENVIRON SRS CENTRIFUGE</h3>
              </div>
              <div className="location-wrap">
                {_map(l.centrifugeLocations, pl => {
                  return (
                    <div key={pl.id} className="location">
                      {pl.name && <p className="name">{pl.name}</p>}
                      {pl.addressLine1 && <p>{pl.addressLine1}</p>}
                      {pl.addressLine2 && <p>{pl.addressLine2}</p>}
                      {pl.postalCode && <p>{pl.postalCode}</p>}
                      {pl.country && <p>{pl.country}</p>}
                      {pl.phone && (
                        <p>
                          <span>Phone:</span> {pl.phone}
                        </p>
                      )}
                      {pl.fax && (
                        <p>
                          <span>Fax:</span> {pl.fax}
                        </p>
                      )}
                      {pl.website && (
                        <a target="blank" href={pl.website} className="orange">
                          {pl.website}
                        </a>
                      )}
                      {pl.email && (
                        <a href={`mailto:${pl.email}`} className="orange">
                          {pl.email}
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            </Fragment>
          )}
        </LocationModule>
      );
    });
  }

  function renderLocationDropModules() {
    function handleClick(id) {
      setSelected(selected === id ? null : id);
      setTimeout(() => {
        scrollToElement(`.${id}`);
      }, 0);
    }
    return _map(locations, l => {
      const hasPlugs = l.plugLocations.length > 0;
      const hasCents = l.centrifugeLocations.length > 0;
      const open = selected === l.id;
      return (
        <LocationDrop open={open} key={`${l.id}-drop`} className={l.id}>
          <div className="title">
            <button onClick={() => handleClick(l.id)}>
              <span>{l.name}</span> <img src={Down} alt="down-icon" />
            </button>
          </div>
          {open && (
            <Fragment>
              {!hasPlugs && !hasCents && (
                <p className="oops">
                  Oops! No listed suppliers in {l.name} yet.
                </p>
              )}
              {hasPlugs && (
                <Fragment>
                  <div className="product">
                    <h3>Piercepoint TTB Plug</h3>
                  </div>
                  <div className="location-wrap">
                    {_map(l.plugLocations, pl => {
                      return (
                        <div key={pl.id} className="location">
                          {pl.name && <p className="name">{pl.name}</p>}
                          {pl.addressLine1 && <p>{pl.addressLine1}</p>}
                          {pl.addressLine2 && <p>{pl.addressLine2}</p>}
                          {pl.postalCode && <p>{pl.postalCode}</p>}
                          {pl.country && <p>{pl.country}</p>}
                          {pl.phone && (
                            <p>
                              <span>Phone:</span> {pl.phone}
                            </p>
                          )}
                          {pl.fax && (
                            <p>
                              <span>Fax:</span> {pl.fax}
                            </p>
                          )}
                          {pl.website && (
                            <a
                              target="blank"
                              href={pl.website}
                              className="orange"
                            >
                              {pl.website}
                            </a>
                          )}
                          {pl.email && (
                            <a href={`mailto:${pl.email}`} className="orange">
                              {pl.email}
                            </a>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              )}
              {hasCents && (
                <Fragment>
                  <div className="product">
                    <h3>ENVIRON SRS CENTRIFUGE</h3>
                  </div>
                  <div className="location-wrap">
                    {_map(l.centrifugeLocations, pl => {
                      return (
                        <div key={pl.id} className="location">
                          {pl.name && <p className="name">{pl.name}</p>}
                          {pl.addressLine1 && <p>{pl.addressLine1}</p>}
                          {pl.addressLine2 && <p>{pl.addressLine2}</p>}
                          {pl.postalCode && <p>{pl.postalCode}</p>}
                          {pl.country && <p>{pl.country}</p>}
                          {pl.phone && (
                            <p>
                              <span>Phone:</span> {pl.phone}
                            </p>
                          )}
                          {pl.fax && (
                            <p>
                              <span>Fax:</span> {pl.fax}
                            </p>
                          )}
                          {pl.website && (
                            <a
                              target="blank"
                              href={pl.website}
                              className="orange"
                            >
                              {pl.website}
                            </a>
                          )}
                          {pl.email && (
                            <a href={`mailto:${pl.email}`} className="orange">
                              {pl.email}
                            </a>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
        </LocationDrop>
      );
    });
  }

  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <h2>Select your continent to find a supplier</h2>
            <div className="image-wrap">
              {renderPoints()}
              {renderImages()}
            </div>
            <div className="location-module-wrap">
              {renderLocationModules()}
            </div>
            <div className="location-drop-wrap">
              {renderLocationDropModules()}
            </div>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
