import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import useWindow from '../hooks/useWindow';
import ContactDiagram from '../components/ContactDiagram';
import ContactForm from '../components/ContactForm';

const contactQuery = graphql`
  {
    datoCmsContact {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroBackground {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroMobileBackground {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroTextImage {
        url
      }
      contactFormSubjects {
        id
        title
      }
      locations {
        id
        name
        image {
          url
        }
        plugLocations {
          id
          name
          addressLine1
          addressLine2
          country
          email
          fax
          phone
          postalCode
          website
        }
        centrifugeLocations {
          id
          name
          addressLine1
          addressLine2
          country
          email
          fax
          phone
          postalCode
          website
        }
      }
    }
  }
`;

export default function Contact() {
  const data = useStaticQuery(contactQuery);
  const { isSmall } = useWindow();

  const {
    seoMetaTags,
    heroBackground,
    heroMobileBackground,
    heroTextImage,
    locations,
    contactFormSubjects,
  } = data.datoCmsContact;

  const heroProps = {
    background: heroBackground.fluid,
    backgroundMobile: heroMobileBackground.fluid,
    textImage: heroTextImage.url,
    isMobile: isSmall,
    pathname: '/contact',
  };

  const diagramProps = {
    locations,
  };
  const formProps = {
    contactFormSubjects,
  };

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Hero {...heroProps} />
      <div id="intro" />
      <ContactDiagram {...diagramProps} />
      <ContactForm {...formProps} />
    </Fragment>
  );
}
